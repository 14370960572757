import React, { useState } from "react"
import Layout from "../components/layout"
import Footer from "../components/layout/newFooter"
import inetevol from "../assets/images/InternetEvolutionTapestry.jpg";

const Mural = () => {
  // State to manage if the image is magnified
  const [isMagnified, setIsMagnified] = useState(false)

  // Function to toggle magnification
  const toggleMagnification = () => {
    setIsMagnified(!isMagnified)
  }

  return (
      <Layout>
    <h1 style={{ margin: "30px", textAlign: "center" }}>Internet Evolution Tapestry</h1>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "20px",
    }}
  >
    <div // Image container for independent scrolling
      style={{
        overflow: isMagnified ? "auto" : "hidden", // Apply scrolling here
        maxWidth: "100%", // Ensure the container does not exceed the viewport width
        maxHeight: "90vh", // Optional: Limit the container height to ensure it's within the viewport
      }}    >
      <img
        src={inetevol}
        alt="Internet History Tapestry"
        style={{
          width: isMagnified ? "auto" : "100%", // 'auto' for natural size when magnified
          height: isMagnified ? "auto" : "auto", // Adjust as needed
          cursor: "zoom-in",
          objectFit: isMagnified ? "contain" : "cover", // 'contain' ensures the image fits within the container without distortion
          maxWidth: isMagnified ? "none" : "100%", // Allow full size when magnified
        }}
        onClick={toggleMagnification}
      />
    </div>
    <div
      style={{
        maxWidth: "800px",
        width: "100%",
        textAlign: "justify",
        margin: "20px",
      }}
    >
      The Internet Evolution Tapestry is a project by visual journalist
      Eileen Clegg in collaboration with Dr. Vinton G. Cerf, known as father
      of the internet with fellow TCP/IP inventor Dr. Robert E. Kahn, and
      many other Internet pioneers who provided information and insights
      that enabled this pictorial mapping of how their world-changing but
      largely invisible innovation evolved from the pre-Internet seeds of
      the ARPAnet to the 5.3-billion strong Internet that impacts almost
      every aspect of life today. Draft 2 of the Internet Evolution Tapestry
      was shown at the 50th Anniversary of the Internet Celebrations May 19
      and 20, inspiring new conversations about the past and the future that
      will be reflected in the next version. Eileen was assisted by Ellen
      Lovelidge, and other colleagues with their company, Tapestry, which
      has automated the visual capture of online conversations.
    </div>
  </div>
  <Footer />
</Layout>
  )
}

export default Mural